<template>
    <div>
        <a-config-provider :locale="zh_CN">
            <a-card>
                <a-row>
                    <a-col :span="24">
                        <a-row style="margin-bottom: 20px;">
                            <a-col :span="24">
                                <a-range-picker @change="DiscoverytimeChange"
                                    :placeholder="[l('StartTime'), l('WEEndDate')]" v-model="nowTime"
                                    style=" width: 250px;text-align: center;margin-right:10px;" />
                                <a-tree-select style="width: 200px;margin-right:10px;" :tree-data="treeData"
                                    :replace-fields="treeReplaceFields"
                                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                    :placeholder="l('audititem.audititemgroupid')" allow-clear tree-default-expand-all
                                    @change="onTreeSelect">
                                </a-tree-select>
                                <a-tree-select style="width: 200px;margin-right:10px;" :tree-data="pointTreeData"
                                    :replace-fields="pointTreeRepData"
                                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto',width: '300px' }"
                                    :placeholder="l('220')" @change="pointTreeRepChange" allow-clear
                                    tree-default-expand-all>
                                </a-tree-select>
                                <a-tree-select style="width: 200px" :tree-data="workProcessData"
                                    :replace-fields="treeReplace"
                                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                    :placeholder="l('audititem.audititemtypeid')" allow-clear tree-default-expand-all
                                    @change="workProcessClassChange">
                                </a-tree-select>
                            </a-col>
                        </a-row>
                        <a-spin :spinning="isLoading">
                            <div id="data" style="height: 0px;text-align: center;font-size: 2rem;line-height: 100px;">
                            </div>
                            <div style="height: 720px;width: 100%;text-align: left" id="mainScrapCode">

                            </div>
                        </a-spin>
                    </a-col>
                </a-row>

            </a-card>
        </a-config-provider>
    </div>
</template>

<script>
    import { AppComponentBase } from "@/shared/component-base";
    import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
    import { AuditItemGroupServiceProxy, ReportServiceProxy, AuditItemtypeServiceProxy,AuditPointServiceProxy } from '../../../shared/service-proxies';
    import { ModalHelper } from '@/shared/helpers';
    import { fileDownloadService } from "../../../shared/utils";
    import * as echarts from 'echarts';
    import moment from "moment";

    let _this;
    export default {
        name: "audit-item-issues-report",
        mixins: [AppComponentBase],
        components: {
        },
        created() {
            _this = this;
            this.AuditItemGroupServiceProxy = new AuditItemGroupServiceProxy(this.$apiUrl, this.$api);
            this.ReportServiceProxy = new ReportServiceProxy(this.$apiUrl, this.$api);
            this.AuditItemtypeServiceProxy = new AuditItemtypeServiceProxy(this.$apiUrl, this.$api);
            this.AuditPointServiceProxy = new AuditPointServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            this.nowDate();
            this.getTree();
            this.getPointTreeData();
            this.getWorkProcessData();
        },
        data() {
            return {
                zh_CN,
                isLoading: false,//加载中
                treeData: [],
                treeReplaceFields: {
                    title: 'name',
                    key: 'id',
                    value: 'id'
                },
                treeReplace: {
                    title: 'name',
                    key: 'id',
                    value: 'id'
                },
                pointTreeData:[],
                pointTreeRepData: {
					title: 'name',
					key: 'id',
					value: 'id'
				},
                pointId:undefined,

                groupingId: undefined,
                workProcessClass: undefined,
                workProcessData: [],
                teby: [],
                enable: [],
                startDate: undefined,
                endDate: undefined,
                problemNum: [],
                areaList: [],
                nowTime: [],
                isClear: false
            }
        },
        computed: {
            option() {
                return {
                    title: {
                        text: this.l('AuditItemIssueStatistics'),
                        subtext: '',
                        x: 'center',
                        textStyle: {
                            fontSize: 26,
                        },
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                        },
                        formatter: function (a) {
                            return a[0].data.label + '<br/>' + '<br/>' + '问题数量(Issue number)：' + a[0].data.value;
                        },
                    },
                    legend: {
                        top: this.areaList.length == 1 ? '88%' : '90%',
                        x: 'center', //判断区域个数计算高度
                        data: [this.l('ProblemNum')],
                    },
                    grid: {
                        bottom: this.areaList.length == 1 ? '16%' : '12%',
                        containLabel: true,
                    },
                    calculable: true,
                    xAxis: {
                        position: 'top',
                        minInterval: 1,
                        type: 'value',
                        boundaryGap: [0, 0.01],
                    },
                    yAxis: {
                        type: 'category',
                        data: this.areaList,
                        inverse: true,
                    },
                    series: [
                        {
                            name: this.l('ProblemNum'),
                            type: 'bar',
                            barMaxWidth: 50, //最大宽度
                            data: this.problemNum,
                            label: {
                                show: true,
                                position: 'right',
                                formatter: function (v) {
                                    return v.data.value
                                }
                            },
                        },
                    ],
                }
            },
        },
        methods: {
            pointTreeRepChange(key) {
				console.log(key);
				this.pointId = key;
				this.getData();
			},

            //获取审核点
            getPointTreeData() {
                this.isLoading = true;
                this.AuditPointServiceProxy.getAllData(

                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    // console.log(res)
                    this.pointTreeData = JSON.parse(JSON.stringify(res));
                })
            },

            //获取当前年
            nowDate() {
                let startTime = new Date().getFullYear() + '-01-01';
                let endTime = new Date().getFullYear() + '-12-31';
                this.nowTime = [moment(startTime), moment(endTime)];
                this.startDate = moment(startTime);
                this.endDate = moment(endTime);
                this.getData();
            },

            //获取类型数据
            getTree() {
                this.isTreeLoading = true;
                this.AuditItemGroupServiceProxy.getPaged(
                ).finally(() => {
                    this.isTreeLoading = false;
                }).then(rel => {
                    console.log(rel);
                    this.treeData = JSON.parse(JSON.stringify(rel));
                })
            },
            //审核项分组选择
            onTreeSelect(key) {
                this.groupingId = key;
                this.isClear = true;
                this.getData();
            },
            //获取数据
            getData() {
                this.isLoading = true;
                this.ReportServiceProxy.getAuditItemIssueStatisticsForEcharts(
                    this.startDate,
                    this.endDate,
                    this.groupingId,
                    this.workProcessClass,
                    this.pointId
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    this.areaList = [];
                    this.problemNum = [];
                    //循环赋值数据
                    res.forEach((item, index) => {
                        //取Top10数据
                        if (index < 10) {
                            this.areaList.push(item.sn);
                            let data = {};
                            data.value = item.count;
                            data.label = item.auditItemName;
                            this.problemNum.push(data);
                        }
                    });
                    console.log(this.problemNum)
                    if (this.areaList.length == 0) {
                        this.myeChart.clear();
                        document.getElementById("data").style.height = "200px";
                        document.getElementById("data").innerHTML = this.l('NoData');
                        document.getElementById("mainScrapCode").style.height = "0px";
                    } else {
                        if (this.areaList.length == 1) {
                            document.getElementById("mainScrapCode").style.height = "200px";
                        } else if (this.areaList.length == 2) {
                            document.getElementById("mainScrapCode").style.height = "300px";
                        } else if (this.areaList.length > 2) {
                            document.getElementById("mainScrapCode").style.height = "720px";
                        }

                        this.charts();
                    }
                })
            },

            charts() {
                this.myeChart = echarts.init(document.getElementById("mainScrapCode"))

                this.myeChart.clear();

                document.getElementById("data").style.height = "0px";
                document.getElementById("data").innerHTML = "";

                this.myeChart.setOption(this.option);
                this.myeChart.resize();
                window.addEventListener('resize', () => {
                    this.myeChart.resize();
                })
            },

            //获取审核项类型
            getWorkProcessData() {
                this.isLoading = true;
                this.AuditItemtypeServiceProxy.getAllDate(
                    this.filterText ? this.filterText : undefined,
                    undefined,
                    this.request.maxResultCount,
                    this.request.skipCount
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);
                    this.workProcessData = JSON.parse(JSON.stringify(res));
                })
            },
            workProcessClassChange(key) {
                this.workProcessClass = key;
                this.isClear = true;
                this.getData();
            },
            DiscoverytimeChange(date, dateString) {
                this.startDate = date[0];
                this.endDate = date[1];
                this.isClear = true;
                this.getData();
            },
        }
    }
</script>

<style>
    .pagination {
        margin: 10px auto;
        text-align: right;
    }

    .con {
        padding-bottom: 10px;
    }
</style>